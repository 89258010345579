// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx": () => import("/opt/build/repo/node_modules/@narative/gatsby-theme-novela/src/templates/articles.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx" */),
  "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx": () => import("/opt/build/repo/node_modules/@narative/gatsby-theme-novela/src/templates/article.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-gamebyrd-js": () => import("/opt/build/repo/src/pages/case-studies/gamebyrd.js" /* webpackChunkName: "component---src-pages-case-studies-gamebyrd-js" */),
  "component---src-pages-case-studies-guess-the-throne-js": () => import("/opt/build/repo/src/pages/case-studies/guess-the-throne.js" /* webpackChunkName: "component---src-pages-case-studies-guess-the-throne-js" */),
  "component---src-pages-case-studies-its-a-date-js": () => import("/opt/build/repo/src/pages/case-studies/its-a-date.js" /* webpackChunkName: "component---src-pages-case-studies-its-a-date-js" */),
  "component---src-pages-case-studies-pride-of-the-meadows-js": () => import("/opt/build/repo/src/pages/case-studies/pride-of-the-meadows.js" /* webpackChunkName: "component---src-pages-case-studies-pride-of-the-meadows-js" */),
  "component---src-pages-contact-us-js": () => import("/opt/build/repo/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-our-team-js": () => import("/opt/build/repo/src/pages/join-our-team.js" /* webpackChunkName: "component---src-pages-join-our-team-js" */),
  "component---src-pages-our-process-js": () => import("/opt/build/repo/src/pages/our-process.js" /* webpackChunkName: "component---src-pages-our-process-js" */),
  "component---src-pages-our-work-js": () => import("/opt/build/repo/src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-thank-you-js": () => import("/opt/build/repo/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

